import { Container, Graphics, Sprite } from "pixi.js";
import { ImageLoader, ImageResources } from "../lib/imgLoader";

// class FEC extends Container {
//   constructor() {
//     super();
//     this.addChild(new FlyEnemy());
//   }
// }

type enemyType = "note1" | "note2";

class FlyEnemy extends Sprite {
  constructor(type: enemyType) {
    super(ImageResources[type].texture);
  }
}

export default FlyEnemy;
