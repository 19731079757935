import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import gsap from "gsap/all";
import { BPS } from "../constant";
import { TrackTime } from "../decorator/trackTime";
import randomcolor from "randomcolor";
import {
  IEnemyBeatInfo,
  EnemyBeatInfoStore,
} from "../store/EnemyBeatInfoStore";
import timeStore from "../store/time";

const CIRCLE_DISTANCE = 200;

const MAX_RADIUS = 15;

class ExplodeEffectContainer extends Container {
  // gsapExplodeContainer: Container;
  // explodeEffects: Set<ExplodeEffect>;
  constructor() {
    super();

    EnemyBeatInfoStore.subscribe((store) => {
      let gsapExplodeContainer = new Container();
      gsapExplodeContainer.y = 0;
      let explodeEffects = new ExplodeEffect(
        store.enemyBeatInfo,
        gsapExplodeContainer
      );
      this.addChild(explodeEffects);
      gsap.to(gsapExplodeContainer, {
        y: CIRCLE_DISTANCE,
        duration: BPS / 4,
        onComplete: () => {
          this.removeChild(explodeEffects);
        },
      });
    });
  }
}

class ExplodeEffect extends Graphics {
  directionArray: Array<{
    direction: number;
    distanceRatio: number;
    color: number;
    alpha: number;
  }> = [];
  enemyBeatInfo: IEnemyBeatInfo;
  trackTime: number = 0;
  startTime: number;

  gsapExplodeContainer: Container;
  constructor(enemyBeatInfo: IEnemyBeatInfo, gsapExplodeContainer: Container) {
    super();
    // this.clear()
    this.enemyBeatInfo = enemyBeatInfo;
    this.gsapExplodeContainer = gsapExplodeContainer;

    this.startTime = timeStore.getState().trackTime;
    for (let i = 0; i < 10; i++) {
      this.directionArray.push({
        direction: Math.random() * Math.PI * 2,
        distanceRatio: Math.random(),
        alpha: this.enemyBeatInfo.rank === "Just" ? 1 : 0.5,
        color:
          this.enemyBeatInfo.rank === "Just"
            ? parseInt(
                randomcolor({
                  hue: "pink",
                }).substr(1),
                16
              )
            : 0xffffff,
        // direction: Math.random() * Math.PI * 2,
      });
    }
    this.draw();
  }
  @TrackTime()
  draw() {
    this.clear();
    this.directionArray.forEach((element) => {
      this.beginFill(element.color, element.alpha);

      let x =
        this.enemyBeatInfo.x +
        element.distanceRatio *
          this.gsapExplodeContainer.y *
          Math.cos(element.direction);
      let y =
        this.enemyBeatInfo.y +
        element.distanceRatio *
          this.gsapExplodeContainer.y *
          Math.sin(element.direction);

      let radius =
        MAX_RADIUS -
        this.gsapExplodeContainer.y / (CIRCLE_DISTANCE / MAX_RADIUS);
      this.drawCircle(x, y, radius);
    });

    if (this.trackTime - this.startTime > (BPS / 4) * 1000) {
      (this as any).unSubdrawTime();
    }
  }
}

export default ExplodeEffectContainer;
