import { Graphics, Sprite } from "pixi.js";
import { BEAT_PER_METER, SEQ_PER_BEAT } from "../constant";
import { gsapToPromise } from "../lib/track";
import gsap from "gsap";
import timeStore from "../store/time";
import { ImageResources } from "../lib/imgLoader";

const ROTATE_ANGLE = Math.PI / 12;
const TIME = 0.5;

class David extends Sprite {
  trackSeq: number = 0;

  constructor() {
    super(ImageResources.david.texture);
    this.anchor.set(0.5, 0.5);
    this.x = 900;
    this.y = 150;

    this.start();
  }

  async roll() {
    await gsapToPromise(this, {
      rotation: ROTATE_ANGLE,
      // ease: "ease-out",
      duration: 0.3,
    });
    await gsapToPromise(this, {
      rotation: 0,
      ease: "linear",
      duration: 1,
    });
  }

  start() {
    timeStore.subscribe(async (store) => {
      if (store.trackSeq === this.trackSeq) {
        return;
      }

      if (store.trackSeq === 43 * BEAT_PER_METER * SEQ_PER_BEAT) {
        gsap.to(this, {
          x: 700,
          y: 350,
          duration: 1,
        });
      }
      if (store.trackSeq === 53 * BEAT_PER_METER * SEQ_PER_BEAT) {
        gsap.to(this, {
          x: 900,
          y: 350,
          duration: 1,
        });
      }

      this.trackSeq = store.trackSeq;
    });
  }
}

export default David;
