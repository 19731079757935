import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Sprite } from "@pixi/sprite";
import { TilingSprite } from "@pixi/sprite-tiling";
import { Text } from "@pixi/text";
import gsap from "gsap/src/all";
import { STAGE_WIDTH } from "../constant";
import { TrackTime } from "../decorator/trackTime";
import { ImageResources } from "../lib/imgLoader";
import scoreStore from "../store/score";
import ScoreStore from "../store/score";

const RANDOM_RANGE = 20;

type IRank = "Just" | "Good" | "Miss";

class ComboContainer extends Container {
  // comboGraphics: Array<ComboFrame> = [];
  constructor() {
    super();

    // this.comboGraphic();
    ScoreStore.subscribe((store: any) => {
      if (store.hit.type !== "Just" && store.hit.type !== "Good") {
        return;
      }

      let comboGraphic = new StripeCombo(store.hit.type);
      this.addChild(comboGraphic);
      comboGraphic.alpha = 0;
      comboGraphic.x =
        (STAGE_WIDTH - 85) / 2 +
        Math.random() * RANDOM_RANGE -
        RANDOM_RANGE * 2;

      let y = 50 + Math.random() * RANDOM_RANGE - RANDOM_RANGE * 2;
      comboGraphic.y = y + 10;

      gsap.to(comboGraphic, {
        alpha: 1,
        y,
        duration: 0.1,
      });

      setTimeout(() => {
        this.removeChild(comboGraphic);
      }, 2000);
    });
  }
}

abstract class ComboFrame extends Container {
  frame: Sprite;
  abstract content: Graphics | TilingSprite;
  constructor(rank: IRank) {
    super();
    this.sortableChildren = true;
    this.frame = new Sprite(ImageResources.combo.texture);
    this.addChild(this.frame);

    // this.rank = new

    let rankSprite = new Sprite(
      rank === "Just"
        ? ImageResources.just.texture
        : ImageResources.good.texture
    );
    this.addChild(rankSprite);

    rankSprite.x = 22;
    rankSprite.y = 25;
    rankSprite.zIndex = 10;

    // this.content = new Graphics();
    // this.addChild(this.content);
  }

  // // trackTime
  // @TrackTime()
  // draw(trackTime: number) {}
}

class StripeCombo extends ComboFrame {
  content: TilingSprite;
  trackTime: number = 0;
  text: Text;
  randomOffsetY: number;
  constructor(rank: IRank) {
    super(rank);

    this.content = new TilingSprite(ImageResources.stripe.texture!, 166, 94);
    this.addChild(this.content);

    this.content.x = 2;
    this.content.y = 20;
    this.randomOffsetY = Math.random() * 100;

    this.text = new Text(`${scoreStore.getState().hit.combo}`, {
      fontFamily: "IPix",
      fontSize: 30,
      fill: 0xf7d1fc,
      align: "center",
      stroke: 0x000000,
      // dropShadow:
    });
    this.text.x = 84;
    this.text.y = 70;
    this.text.anchor.set(0.5, 0);
    this.addChild(this.text);

    this.tilingUp(0);

    setTimeout(() => {
      (this as any).unSubtilingUpTime();
    }, 2000);
  }
  @TrackTime()
  tilingUp(trackTime: number) {
    this.content.tilePosition.y = (trackTime / 6) % 90;
  }
}

export default ComboContainer;
