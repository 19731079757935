import { meterToSeq } from "../lib/track";

const BPM = 128; // Midnight Lady
const BPS = BPM / 60;

// const TRACK_PIXEL_PER_BEAT = 200;
const OFFSET = 400;
const SEQ_PER_BEAT = 4;

const BEAT_PER_METER = 4;

const ROAD_PIXEL_PER_BEAT = 200;

const NOTE_WIDTH = 30;
const NOTE_HEIGHT = 30;

const GROUND_Y = 400;

const PLAYER_X = 150;

const STAGE_WIDTH = 800;
const STAGE_HEIGHT = 600;
const MEMO_SPEED = (BPS * ROAD_PIXEL_PER_BEAT) / 1000;

export {
  GROUND_Y,
  BPM,
  BEAT_PER_METER,
  STAGE_WIDTH,
  BPS,
  ROAD_PIXEL_PER_BEAT,
  OFFSET,
  PLAYER_X,
  SEQ_PER_BEAT,
  NOTE_WIDTH,
  NOTE_HEIGHT,
  STAGE_HEIGHT,
  MEMO_SPEED,
};
