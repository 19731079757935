import { meterToSeq } from "../lib/track";

export type IENEMY_JSON = Record<
  number,
  {
    type: number;
    beated: boolean;
  }
>;

const gen1MeterArray = (seq: number) => {
  return [seq, seq + 0.25, seq + 0.5, seq + 0.75];
};

const oneSeq = 0.25 / 4;
const twoSeq = oneSeq * 2;

const ENEMY_ARRAY = [
  ...gen1MeterArray(5),
  ...gen1MeterArray(7),
  ...gen1MeterArray(9),
  ...gen1MeterArray(11),
  ...gen1MeterArray(13),

  // const
  ...gen1MeterArray(14),
  15,
  15.5,
  ...gen1MeterArray(18),
  19,
  19.5,
  20,
  20.25,
  20.5,
  // saxo
  21,
  22,
  22.375,
  22.75,
  23,
  23.625,
  23.875,
  24,
  24.375,
  24.75,
  25,
  25.625,
  26,
  26.375,
  26.75,
  27,
  27.625,
  27.875,
  28,
  // 转场
  29,
  29.5,

  30,
  30.5,
  30.625,

  32,
  32.5,
  32.625,

  34,
  34.5,
  34.625,

  36,
  36.5,
  36.625,

  37.5,
  37.5 + (0.25 / 4) * 3,
  37.875,
  // 夜色2
  38,
  38.5,
  38.625,
  38.75,

  40,
  40.5,
  40.625,
  40.75,

  42,
  42.5,
  42.625,
  42.75,

  43,

  // BASS
  46 + oneSeq * 3,
  46.25 + oneSeq * 2,
  46.5,
  46.5 + oneSeq * 3,
  47,
  47.25 + oneSeq * 2,
  47.5 + oneSeq * 2,
  48 + oneSeq * 2,
  48.25,
  48.5,
  48.75,
  49.25 + twoSeq,
  49.75 + oneSeq,

  ...[
    46 + oneSeq * 3,
    46.25 + oneSeq * 2,
    46.5,
    46.5 + oneSeq * 3,
    47,
    47.25 + oneSeq * 2,
    47.5 + oneSeq * 2,
    48 + oneSeq * 2,
    48.25,
    48.5,
    48.75,
    49.25 + twoSeq,
    49.75 + oneSeq,
  ].map((item) => item + 4),

  ...[
    30,
    30.5,
    30.625,

    32,
    32.5,
    32.625,

    34,
    34.5,
    34.625,

    36,
    36.5,
    36.625,

    37.5,
    37.5 + (0.25 / 4) * 3,
    37.875,
    // 夜色2
  ].map((item) => item + 24),

  62,
].map((item) => {
  return meterToSeq(item - 1);
});

const ENEMY_JSON: IENEMY_JSON = {};

ENEMY_ARRAY.forEach((item) => {
  ENEMY_JSON[item] = {
    type: ~~(Math.random() * 2),
    beated: false,
  };
});

console.log(ENEMY_ARRAY);

export { ENEMY_ARRAY, ENEMY_JSON };
