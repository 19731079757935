import { Graphics } from "@pixi/graphics";
import gsap from "gsap/src/all";
import { Container, Sprite, TilingSprite } from "pixi.js";
import { MEMO_SPEED, STAGE_WIDTH } from "../constant";
import { TrackSeq, TrackTime } from "../decorator/trackTime";
import { ImageResources } from "../lib/imgLoader";
import { meterToSeq } from "../lib/track";
import store from "../store/key";

class BridgeWall extends Container {
  bars: TilingSprite;
  wall: Graphics;
  trackSeq: number = 0;
  trackTime: number = 0;
  constructor() {
    super();
    this.bars = new TilingSprite(ImageResources.bars.texture!, 800, 100);
    this.wall = new Graphics();
    this.wall.beginFill(0x000000);
    this.wall.drawRect(0, 60, STAGE_WIDTH, 50 + 300);
    this.wall.endFill();
    this.addChild(this.bars);
    this.addChild(this.wall);
    this.y = 434;

    this.t(0);
    this.seq(0);
  }

  @TrackSeq()
  seq(trackSeq: number) {
    if (trackSeq === meterToSeq(28.25)) {
      gsap.to(this, {
        y: 324,
      });
    }

    if (trackSeq === meterToSeq(53)) {
      gsap.to(this, {
        y: 434,
      });
    }
  }

  @TrackTime()
  t(trackTime: number) {
    this.bars.tilePosition.x = -MEMO_SPEED * trackTime * 0.4;
  }
}

export default BridgeWall;
