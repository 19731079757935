import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";

import { BEAT_PER_METER, BPS, SEQ_PER_BEAT } from "../constant";
import gsap from "gsap";
import timeStore from "../store/time";
import { TrackTime } from "../decorator/trackTime";
import randomColor from "randomcolor";

const WINDOW_TYPE = [
  [12, 4],
  [8, 5],
  [6, 4],
  [6, 6],
];

const MAX_WINDOWS = WINDOW_TYPE[0][0];

const WINDOWS_SIZE = 8;
const GAP = 20;

let buildings: Array<{ col: number; row: number }> = [];

for (let i = 0; i < 100; i++) {
  let windowType = WINDOW_TYPE[~~(Math.random() * WINDOW_TYPE.length)];

  buildings.push({
    row: windowType[0],
    col: windowType[1],
  });
}

class Buildings extends Container {
  buildingGraphics: Array<Graphics>;
  trackSeq: number = 0;
  trackTime: number = 0;
  constructor() {
    super();

    this.buildingGraphics = [];
    let startOffsetX = 0;

    this.scale.set(1, 0);

    for (let i = 0; i < buildings.length; i++) {
      let building = new Graphics();

      let buildingWidth = (buildings[i].col * 2 + 1) * WINDOWS_SIZE;
      let buildingHeight = (buildings[i].row * 2 + 1) * WINDOWS_SIZE;

      // 普通的楼房
      building.beginFill(0x000000);
      building.drawRect(0, 0, buildingWidth, buildingHeight);

      // 窗户

      for (let j = 0; j < buildings[i].col; j++) {
        for (let k = 0; k < buildings[i].row - 1; k++) {
          if (Math.random() < 0.3) {
            building.beginFill(
              parseInt(randomColor({ hue: "purple" }).substr(1), 16)
            );
            building.drawRect(
              j * WINDOWS_SIZE * 2 + WINDOWS_SIZE,
              k * WINDOWS_SIZE * 2 + WINDOWS_SIZE,
              WINDOWS_SIZE,
              WINDOWS_SIZE
            );
          }
        }
      }

      // 设置楼房的位置
      building.x = startOffsetX;
      building.y = (MAX_WINDOWS * 2 + 1) * WINDOWS_SIZE; // 最高的楼房的位置
      building.pivot.set(buildingWidth / 2, buildingHeight); // 设置pivot到最底部
      this.buildingGraphics.push(building);

      startOffsetX += GAP + buildingWidth;
    }
    this.x = 200;
    this.y = 0;
    this.addChild(...this.buildingGraphics);
  }

  @TrackTime()
  move(trackTime: any) {
    this.x = -(trackTime / 1000) * BPS * SEQ_PER_BEAT * 4;

    // this.x = -trackTime * MEMO_SPEED Z+ ;
    // console.log(this.x);
  }

  start() {
    this.position.y = 100 + 200;
    this.move(0);
    timeStore.subscribe(async (store: any) => {
      if (this.trackSeq === store.trackSeq) {
        return;
      }
      if (store.trackSeq === 28.75 * BEAT_PER_METER * SEQ_PER_BEAT) {
        // if (store.trackSeq === 2 * BEAT_PER_METER * SEQ_PER_BEAT) {
        // 尺寸还原
        gsap.to(this.scale, {
          y: 1,
          duration: BPS / 4,
        });

        gsap.to(this, {
          y: 100,
          duration: BPS / 4,
        });
      }

      if (store.trackSeq === 52.75 * BEAT_PER_METER * SEQ_PER_BEAT) {
        gsap.to(this.scale, {
          y: 0,
          duration: BPS / 4,
        });

        gsap.to(this, {
          y: 300,
          duration: BPS / 4,
        });
      }
      // 设置跳动
      if (store.trackSeq % SEQ_PER_BEAT === 0) {
        for (let i = 0; i < this.buildingGraphics.length; i++) {
          let yo = Math.random() * 0.2 + 0.9;
          gsap.to(this.buildingGraphics[i].scale, {
            x: yo,
            y: yo,
            duration: 0.1,
          });
        }
      }

      this.trackSeq = store.trackSeq;
    });
  }
}

export default Buildings;
