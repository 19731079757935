import { Sprite } from "@pixi/sprite";
import { Loader, LoaderResource } from "pixi.js";

const Sprites: Record<string, string> = {
  testSprite: "./assets/test.png",
  roadBlock: "./assets/roadBlock.png",
  slope: "./assets/slope.png",
  road: "./assets/road.png",
  sun: "./assets/sun.png",
  sunNight: "./assets/sun-night.png",
  tree: "./assets/tree.png",
  cloud: "./assets/cloun-pixel.png",
  david: "./assets/david.png",
  fujin: "./assets/statue.png",
  sky: "./assets/sky.png",
  note1: "./assets/note1.png",
  note2: "./assets/note2.png",
  bars: "./assets/bars.png",
  combo: "./assets/combo.png",
  stripe: "./assets/stripe.png",
  just: "./assets/just.png",
  good: "./assets/good.png",
};

for (let i = 0; i < 10; i++) {
  Sprites[`wq${i + 1}`] = `./assets/wq${i + 1}.png`;
}

for (let i = 0; i < 3; i++) {
  Sprites[`board${i + 1}`] = `./assets/board${i + 1}.png`;
}

for (let i = 0; i < 4; i++) {
  Sprites[`arm${i + 1}`] = `./assets/arm${i + 1}.png`;
}

let loader = new Loader();

for (const key in Sprites) {
  loader = loader.add(key, Sprites[key]);
}

type Dict<T> = {
  [key in string]: T;
};

let ImageResources: Dict<LoaderResource>;

const ImageLoader = (callback: any) => {
  loader.load((loader, resources) => {
    ImageResources = resources;
    callback();
  });
};

export { ImageLoader, ImageResources };
// Loader
