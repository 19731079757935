import { meterToSeq } from "../lib/track";

export type IBlockJson = Record<
  number,
  {
    type: number;
    jumped: boolean;
    checked: boolean;
  }
>;

let tempArray = [29.5].map((item, index) => {
  return index;
});

let BLOCK_ARRAY = [
  6.25, 6.75, 8.25, 8.75, 10.25, 10.75, 12.25, 12.75, 16.25, 16.75, 17.25,
  17.75,
  // 转场

  29.25, 29.75, 30.75, 31.5, 32.75, 33.5, 34.75, 35.5, 36.75, 39.25, 39.75,

  44, 44.5, 45, 46.875,
  // back
  55, 55.5, 57, 57.5, 59, 59.5,
].map((item) => {
  return meterToSeq(item - 1);
});

let BLOCK_JSON: IBlockJson = {};

BLOCK_ARRAY.forEach((item) => {
  BLOCK_JSON[item] = {
    type: item >= meterToSeq(29) && item < meterToSeq(53) ? 1 : 2,
    jumped: false,
    checked: false,
  };
});

export { BLOCK_ARRAY, BLOCK_JSON };
