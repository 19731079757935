import { Container, Text } from "pixi.js";
import { TrackSeq } from "../decorator/trackTime";
import scoreStore from "../store/score";
import ComboFrame from "./Combo";
import ExplodeEffectContainer from "./ExplodeEffect";
import MusicInfo from "./MusicInfo";

class UI extends Container {
  explodeEffectContainer: ExplodeEffectContainer;
  musicInfo: MusicInfo;
  comboFrame: ComboFrame;
  trackSeq: number = 0;

  constructor() {
    super();

    this.musicInfo = new MusicInfo();
    this.addChild(this.musicInfo);

    this.comboFrame = new ComboFrame();
    this.addChild(this.comboFrame);

    this.explodeEffectContainer = new ExplodeEffectContainer();
    this.addChild(this.explodeEffectContainer);
  }
  // @TrackSeq()
  // showWQName(tseq: number) {

  // }
  start() {
    this.musicInfo.start();
  }
}

export default UI;
