import React, { Fragment, useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import * as PIXI from "pixi.js";
import StartPIXIGame from "./PixiGame";
import Windaw from "./Windaw";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useScoreStore from "./PixiGame/store/score";

function App() {
  let [gameWindowVisible, setGameWindowVisible] = useState<string>("");
  let canvasRef = useRef<HTMLCanvasElement>(null);

  const done = useScoreStore((state) => state.done);

  let [newMail, setNewMail] = useState(true);

  let [textState, setTextState] = useState(0);

  useEffect(() => {
    if (gameWindowVisible !== "game") {
      return;
    }

    StartPIXIGame(canvasRef.current!);
  }, [gameWindowVisible]);

  useEffect(() => {
    if (gameWindowVisible === "info" && textState <= 4) {
      setTimeout(() => {
        setTextState((state) => state + 1);
      }, 3000);
    }
  }, [textState, gameWindowVisible]);

  return (
    <div className="App">
      {/* {!hide && ( */}
      <div className="screen">
        <div className="icons">
          <div
            className="iconContainer"
            onClick={() => {
              if (gameWindowVisible === "") {
                setGameWindowVisible("info");
              }
            }}
          >
            <img alt="game" src="/assets/joystick.png" />
            <div style={{ textAlign: "center" }}>游戏</div>
          </div>
          <div
            className="iconContainer"
            onClick={() => {
              if (gameWindowVisible === "") {
                setGameWindowVisible("mail");
              }
            }}
          >
            <img alt="game" src="/assets/mailbox.png" />
            <div style={{ textAlign: "center" }}>邮件</div>
          </div>
        </div>
        <div className="windowContainer">
          {gameWindowVisible === "game" && (
            <Windaw title="gametitle">
              <canvas
                width={800}
                height={600}
                ref={canvasRef}
                id="game"
              ></canvas>
            </Windaw>
          )}
          {gameWindowVisible === "info" && (
            <Windaw width={400} height={200} title="infotitle">
              {
                <Slider
                  arrows={false}
                  dots={true}
                  infinite={false}
                  // dotsClass={"dots"}
                >
                  <div className="sli">
                    <div className="sli-container">
                      <div>这是个音乐游戏,请注意音量大小</div>
                      <div>请根据音乐节奏敲击按键</div>
                    </div>
                  </div>
                  <div className="sli">
                    <div className="sli-container">
                      <div>这个是你，你需要有自知之明。</div>
                      <img
                        style={{
                          height: "120px",
                        }}
                        alt="wq-complete"
                        src="/assets/wq-complete.png"
                      ></img>
                    </div>
                  </div>
                  <div className="sli">
                    <div className="sli-container">
                      <div>
                        空格是跳，用来躲避障碍，也可以利用滑坡跳得更高！
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "60%",
                          marginTop: "10px",
                          justifyContent: "space-around",
                        }}
                      >
                        <img
                          alt="wq-complete"
                          src="/assets/roadBlock.png"
                        ></img>
                        <img alt="wq-complete" src="/assets/slope.png"></img>
                      </div>
                    </div>
                  </div>
                  <div className="sli">
                    <div className="sli-container">
                      <div>当音符接近你，用A键打碎音符！</div>
                      <img alt="wq-complete" src="/assets/note1.png"></img>
                    </div>
                  </div>
                  <div className="sli">
                    <div className="sli-container">
                      <div>尽情享受吧</div>
                      <div
                        style={{
                          border: "1px solid black",
                          marginTop: "10px",
                          padding: "4px 4px",
                        }}
                        onClick={() => {
                          setGameWindowVisible("game");
                        }}
                      >
                        START
                      </div>
                    </div>
                  </div>{" "}
                </Slider>
              }
            </Windaw>
          )}

          {gameWindowVisible === "mail" && (
            <Windaw width={600} height={460} title="mailtitle">
              <div
                style={{ padding: "8px", paddingLeft: "12px", lineHeight: 2 }}
              >
                {!done ? (
                  <Fragment>
                    <div>没有新的邮件</div>

                    <div
                      style={{
                        border: "1px solid black",
                        padding: "4px 4px",
                        width: "40px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        setGameWindowVisible("");
                      }}
                    >
                      确定
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div>文琪,生日快乐!</div>
                    <div>
                      由于你是我生命中很特别的人，所以给你准备了特殊的礼物
                    </div>
                    <div>可是受限于时间，要做的阉割了不少TAT。</div>
                    <div>如果能再给我几天时间就太好了呀。</div>
                    <div>由于刚刚入门，所以就沿用了安全的“蒸汽波”风格</div>
                    <div>
                      我承认有些糙，但这是时限固定死的的最终结果，我尽力了。
                    </div>
                    <div>不知道你是否满意呢？满意你就眨眨眼（</div>
                    <div>啊，不过我要和你说一点，礼物可不是内卷的</div>
                    <div>
                      我只是普通的好好利用了多出来的时间肝了一场而已啦！
                    </div>
                    <div>
                      悲伤的是，理想情况下，我还能给你再做50个礼物了，屈指可数了呀。
                    </div>
                    <div>希望下次由于更加精进，能做的更有效率做的更好</div>
                    <div>那么下个生日见，敬请期待吧！</div>
                    <div>辰儿上 2021/09/21</div>{" "}
                  </Fragment>
                )}
              </div>
            </Windaw>
          )}

          {done && gameWindowVisible !== "" && gameWindowVisible !== "mail" && (
            <Windaw
              width={300}
              height={150}
              style={{ position: "absolute" }}
              title="mailtitle"
            >
              <div
                style={{
                  top: "400px",
                  left: "400px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  您有新的邮件
                </div>

                <div
                  style={{
                    border: "1px solid black",
                    marginTop: "10px",
                    padding: "4px 4px",
                    textAlign: "center",
                    width: "100px",
                  }}
                  onClick={() => {
                    setGameWindowVisible("");
                  }}
                >
                  返回桌面
                </div>
              </div>
            </Windaw>
          )}
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default App;
