import { Container, Graphics, Matrix } from "pixi.js";
import {
  SEQ_PER_BEAT,
  BPS,
  ROAD_PIXEL_PER_BEAT,
  GROUND_Y,
  STAGE_HEIGHT,
  PLAYER_X,
  BEAT_PER_METER,
} from "../../constant";

import { STAGE_WIDTH } from "../../constant";
import timeStore from "../../store/time";
import keyStore from "../../store/key";
import scoreStore from "../../store/score";
import { seqToPixel, timeToSequence } from "../../lib/track";
import Road from "./Road";
import RoadBlock from "./RoadBlock";
import Tree from "./Tree";

class RoadGround extends Container {
  road: Road;
  tree: Tree;
  roadBlock: RoadBlock;

  constructor() {
    super();

    this.road = new Road();
    this.addChild(this.road);

    this.tree = new Tree();
    this.addChild(this.tree);

    this.roadBlock = new RoadBlock();
    this.addChild(this.roadBlock);
  }

  start() {
    this.roadBlock.start();
    this.road.start(0);
    this.tree.start();
  }
}

export default RoadGround;
