import { PropsWithChildren } from "react";
import "./window.css";

type title = "gametitle" | "infotitle" | "mailtitle";

const Windaw: React.FC<
  PropsWithChildren<{
    title: title;
    width?: number;
    height?: number;
    style?: any;
  }>
> = ({ children, title, width = 800, height = 600, style, ...props }) => {
  return (
    <div
      className="window"
      style={{ width, height: height + 20, ...style }}
      {...props}
    >
      <div className="title">
        <img alt="gametitle" src={`/assets/${title}.png`}></img>
      </div>
      <div className="content" style={{ height }}>
        {children}
      </div>
    </div>
  );
};

export default Windaw;
