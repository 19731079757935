import { Container, Graphics, Matrix } from "pixi.js";
import { SEQ_PER_BEAT, BPS, ROAD_PIXEL_PER_BEAT } from "../constant";

import timeStore from "../store/time";
import keyStore from "../store/key";
import scoreStore from "../store/score";
import FlyEnemy from "./FlyEnemy";
import { gsapToPromise, meterToSeq, sequenceToTime } from "../lib/track";
import gsap from "gsap/src/all";
import { EnemyBeatInfoStore } from "../store/EnemyBeatInfoStore";
import { ENEMY_JSON, IENEMY_JSON } from "../constant/EnemyTrack";

//
const SEQ_OFFSET = 8;

class Enemys extends Container {
  enemysJSON: IENEMY_JSON;
  enemyMap: Map<number, FlyEnemy>;

  trackSeq: number = 0;
  trackTime: number = 0;
  constructor() {
    super();
    this.enemyMap = new Map();
    this.enemysJSON = ENEMY_JSON;
  }

  checkMiss() {
    let notes = this.enemysJSON[this.trackSeq - 3];
    if (notes && !notes.beated) {
      scoreStore.getState().setHit("Miss");
      this.removeChild(this.enemyMap.get(this.trackSeq - 3) as any);
      this.enemyMap.delete(this.trackSeq - 3);
    }
  }

  start() {
    timeStore.subscribe(async (store) => {
      if (store.trackSeq === this.trackSeq) {
        return;
      }

      this.checkMiss();
      this.trackSeq = store.trackSeq;

      if (store.trackSeq % 4 === 0) {
        for (const enemy of this.enemyMap.values()) {
          gsap.to(enemy.scale, {
            x: 1.3,
            y: 1.3,
            duration: 0.1,

            onComplete: () => {
              gsap.to(enemy.scale, {
                x: 1.0,
                y: 1.0,
                duration: 0.1,
              });
            },
          });
        }
      }

      if (this.enemysJSON[store.trackSeq + SEQ_OFFSET * 2]) {
        let enemy = new FlyEnemy("note1");
        enemy.anchor.set(0.5, 0.5);

        let isFujin =
          store.trackSeq > meterToSeq(21 - 2) &&
          store.trackSeq <= meterToSeq(28 - 2);
        let isDavid =
          store.trackSeq > meterToSeq(46 - 2) &&
          store.trackSeq <= meterToSeq(53 - 2);

        let saxmouth = [630, 380];

        let initialXY = isFujin
          ? saxmouth
          : isDavid
          ? [663, 365]
          : [800 + ~~(Math.random() * 100), 200 + ~~(Math.random() * 100)];

        enemy.x = initialXY[0];
        enemy.y = initialXY[1];
        this.addChild(enemy);
        this.enemyMap.set(store.trackSeq + SEQ_OFFSET * 2, enemy);

        // console.log(store.trackSeq + SEQ_OFFSET * 2);

        (async () => {
          await gsapToPromise(enemy, {
            x: 600 + ~~(Math.random() * 100),
            y: 200 + ~~(Math.random() * 100),
            duration: sequenceToTime(SEQ_OFFSET) / 1000,
          });
          await gsapToPromise(enemy, {
            ease: "linear",
            x: 250,
            y: 420,
            duration: sequenceToTime(SEQ_OFFSET) / 1000,
          });
          await gsapToPromise(enemy, {
            ease: "linear",
            x: -100,
            y: 450,
            duration: 0.5,
          });
        })();
      }
    });

    keyStore.subscribe(
      ({ keyName }: any) => {
        if (keyName !== "KeyA") {
          return;
        }

        let hitedSeq = this.trackSeq;
        if (!hitedSeq) {
          return;
        }

        if (this.enemysJSON[hitedSeq] && !this.enemysJSON[hitedSeq].beated) {
          console.log("!!!!!", hitedSeq);

          this.enemysJSON[hitedSeq].beated = true;

          scoreStore.getState().setHit("Just");

          EnemyBeatInfoStore.getState().setEnemyPos({
            x: this.enemyMap.get(hitedSeq)!.x,
            y: this.enemyMap.get(hitedSeq)!.y,
            rank: "Just",
          });

          this.removeChild(this.enemyMap.get(hitedSeq)!);
          this.enemyMap.delete(hitedSeq);
        }

        if (
          this.enemysJSON[hitedSeq - 1] &&
          !this.enemysJSON[hitedSeq - 1].beated
        ) {
          this.enemysJSON[hitedSeq - 1].beated = true;
          scoreStore.getState().setHit("Good");

          if (!this.enemyMap.get(hitedSeq - 1)) {
            return;
          }

          EnemyBeatInfoStore.getState().setEnemyPos({
            x: this.enemyMap.get(hitedSeq - 1)!.x,
            y: this.enemyMap.get(hitedSeq - 1)!.y,
            rank: "Good",
          });

          this.removeChild(this.enemyMap.get(hitedSeq - 1) as any);
          this.enemyMap.delete(hitedSeq - 1);
        } else if (
          this.enemysJSON[hitedSeq + 1] &&
          !this.enemysJSON[hitedSeq + 1].beated
        ) {
          this.enemysJSON[hitedSeq + 1].beated = true;
          scoreStore.getState().setHit("Good");

          if (!this.enemyMap.get(hitedSeq + 1)) {
            return;
          }

          EnemyBeatInfoStore.getState().setEnemyPos({
            x: this.enemyMap.get(hitedSeq + 1)?.x,
            y: this.enemyMap.get(hitedSeq + 1)?.y,
            rank: "Good",
          });
          this.removeChild(this.enemyMap.get(hitedSeq + 1)!);
          this.enemyMap.delete(hitedSeq + 1);
        }
      },
      (state: any) => state.key
    );
  }
}

export default Enemys;
