import create from "zustand/vanilla";

const keyStore = create<any>((set) => ({
  key: {
    keyName: null,
  },

  setKeyPress: (keyName: string) =>
    set(() => ({
      key: {
        keyName,
      },
    })),
}));

// const { getState, setState, subscribe, destroy } = store

export default keyStore;
