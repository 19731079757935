import {
  BPS,
  SEQ_PER_BEAT,
  ROAD_PIXEL_PER_BEAT,
  BEAT_PER_METER,
} from "../constant";
import gsap from "gsap";
const timeToSequence = (time: number) => {
  return ~~((time / 1000) * BPS * SEQ_PER_BEAT);
};

const sequenceToTime = (seq: number) => {
  return (seq / BPS / SEQ_PER_BEAT) * 1000;
};

const seqToPixel = (seq: number) => {
  return (seq * ROAD_PIXEL_PER_BEAT) / SEQ_PER_BEAT;
};

const meterToSeq = (meter: number) => {
  return meter * BEAT_PER_METER * SEQ_PER_BEAT;
};

const gsapToPromise = (
  target: gsap.TweenTarget,
  options: Exclude<gsap.TweenVars, "onComplete">
) => {
  return new Promise<void>((resolve) => {
    gsap.to(target, {
      ...options,
      onComplete: () => {
        resolve();
      },
    });
  });
};

export {
  timeToSequence,
  sequenceToTime,
  seqToPixel,
  gsapToPromise,
  meterToSeq,
};
