import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Text } from "@pixi/text";
import { gsapToPromise } from "../lib/track";

const genInfoFrame = (fontSize: number) => {
  return {
    FONT_SIZE: fontSize,
    HEIGHT: fontSize * 2,
    Y: fontSize / 2 - 1,
  };
};

const BIG_WIDTH = 180;
const SMALL_WIDTH = 110;

class MusicInfo extends Container {
  musicTitle: Container;
  author: Container;
  constructor() {
    super();

    this.musicTitle = new Container();
    this.author = new Container();
    this.drawTitle();
    this.drawAuthor();

    this.musicTitle.x = -BIG_WIDTH;
    this.author.x = -SMALL_WIDTH;
    this.musicTitle.y = 50;
    this.author.y = this.musicTitle.y + genInfoFrame(16).HEIGHT + 10;
    this.addChild(this.musicTitle);
    this.addChild(this.author);
  }
  start() {
    gsapToPromise(this.musicTitle, {
      x: 0,
      duration: 0.5,
    });

    setTimeout(() => {
      gsapToPromise(this.author, {
        x: 0,
        duration: 0.5,
      });
    }, 200);

    setTimeout(() => {
      let arr = [
        gsapToPromise(this.musicTitle, {
          x: -BIG_WIDTH,
          duration: 0.5,
        }),
        gsapToPromise(this.author, {
          x: -SMALL_WIDTH,
          duration: 0.5,
        }),
      ];

      Promise.all(arr).then(() => {
        this.removeChild();
      });
    }, 3000);
  }

  drawTitle() {
    let TITLE = genInfoFrame(16);

    let titleFrame = new Graphics();
    titleFrame.beginFill(0x000000);
    titleFrame.moveTo(0, 0);
    titleFrame.lineTo(BIG_WIDTH, 0);
    titleFrame.lineTo(BIG_WIDTH - TITLE.HEIGHT, TITLE.HEIGHT);
    titleFrame.lineTo(0, TITLE.HEIGHT);
    titleFrame.lineTo(0, 0);
    titleFrame.endFill();
    this.musicTitle.addChild(titleFrame);
    let musicText = new Text(`Midnight Lady`, {
      fill: 0xffffff,
      fontSize: TITLE.FONT_SIZE,
    });
    musicText.x = TITLE.Y;
    musicText.y = TITLE.Y;
    this.musicTitle.addChild(musicText);
  }
  drawAuthor() {
    let TITLE = genInfoFrame(12);

    let authorFrame = new Graphics();
    authorFrame.beginFill(0x000000);
    authorFrame.moveTo(0, 0);
    authorFrame.lineTo(SMALL_WIDTH, 0);
    authorFrame.lineTo(SMALL_WIDTH - TITLE.HEIGHT, TITLE.HEIGHT);
    authorFrame.lineTo(0, TITLE.HEIGHT);
    authorFrame.lineTo(0, 0);
    authorFrame.endFill();
    this.author.addChild(authorFrame);
    let authorText = new Text(`Dirty Androids`, {
      fill: 0xffffff,
      fontSize: TITLE.FONT_SIZE,
    });
    authorText.x = TITLE.Y;
    authorText.y = TITLE.Y;
    this.author.addChild(authorText);
  }
}

export default MusicInfo;
