import { Graphics, Container } from "pixi.js";
import {
  GROUND_Y,
  STAGE_WIDTH,
  STAGE_HEIGHT,
  MEMO_SPEED,
  BPS,
  BEAT_PER_METER,
  SEQ_PER_BEAT,
} from "../../constant";
import { meterToSeq, timeToSequence } from "../../lib/track";
import scoreStore from "../../store/score";
import timeStore from "../../store/time";
import keyStore from "../../store/key";
import RoadBlock from "./RoadBlock";
import gsap from "gsap/all";
import { TrackSeq, TrackTime } from "../../decorator/trackTime";

let GROUND_BOTTOM = STAGE_HEIGHT;

const FAR_POINT_ANGLE_HALF = Math.tan((Math.PI / 180) * 60);
const FAR_POINT_TO_GROUND = STAGE_WIDTH / 2 / FAR_POINT_ANGLE_HALF;
const GROUND_FACE_HEIGHT = STAGE_HEIGHT - GROUND_Y;

const BOTTOM_LENGTH =
  FAR_POINT_ANGLE_HALF * (GROUND_FACE_HEIGHT + FAR_POINT_TO_GROUND) * 2;

type Mode = {
  farPointX: number;
  farPointY: number;
  bottomPointY: number;
  gridSize: number;
  maskY: number;
  maskHeight: number;
  color: number;
};

const MODE1: Mode = {
  farPointX: 400,
  farPointY: 200,
  bottomPointY: 600,
  gridSize: 80,
  maskY: GROUND_Y,
  maskHeight: GROUND_FACE_HEIGHT,
  color: 0xec7cfc,
};

const MODE2: Mode = {
  farPointX: 400,
  farPointY: 400,
  bottomPointY: 510,
  gridSize: 160,
  maskY: GROUND_Y + 32,
  maskHeight: 80,
  color: 0x8a7cfc,
};
class Road extends Graphics {
  trackTime: number = 0;
  trackSeq: number = 0;
  farPointContianer: Container;
  bottomLineContainer: Container;
  gridSizeContainer: Container;
  roadMask: Graphics;
  road: Container;
  mode: Mode;

  constructor() {
    super();
    this.clear();
    this.road = new Container();
    this.mode = MODE1;

    // gsap
    this.farPointContianer = new Container();
    this.farPointContianer.x = this.mode.farPointX;
    this.farPointContianer.y = this.mode.farPointY;

    this.bottomLineContainer = new Container();
    this.bottomLineContainer.y = this.mode.bottomPointY;

    this.gridSizeContainer = new Container();
    this.gridSizeContainer.y = this.mode.gridSize;

    // mask
    this.roadMask = new Graphics();
    this.roadMask.x = 0;
    this.roadMask.y = this.mode.maskY;
    this.roadMask.width = STAGE_WIDTH;
    this.roadMask.height = GROUND_FACE_HEIGHT;
    this.roadMask.lineStyle(0);
    this.roadMask.drawRect(0, 0, STAGE_WIDTH, GROUND_FACE_HEIGHT);
    this.mask = this.roadMask;
    this.addChild(this.roadMask);
    this.draw(0);
    this.groundOffset(0);
  }

  draw(offset: number) {
    let middleLayoutOffset = ((offset * 600) / 490) * 1.15;

    this.clear();
    this.beginFill(0x000000);
    this.drawRect(0, 0, STAGE_WIDTH, STAGE_HEIGHT);
    this.endFill();
    // 地面

    this.lineStyle({
      color: this.mode.color,
      width: 2,
    });

    // 斜线
    for (
      let i = (STAGE_WIDTH - BOTTOM_LENGTH) / 2 - 5000;
      i <
      BOTTOM_LENGTH +
        (STAGE_WIDTH - BOTTOM_LENGTH) / 2 +
        this.gridSizeContainer.y +
        5000;
      i += this.gridSizeContainer.y
    ) {
      this.moveTo(this.farPointContianer.x, this.farPointContianer.y);
      this.lineTo(
        i + (middleLayoutOffset % this.gridSizeContainer.y),
        this.bottomLineContainer.y
      );
    }

    // 横线
    for (
      let i = this.farPointContianer.y === 400 ? 3 : 0, j = 0;
      i <= STAGE_HEIGHT - GROUND_Y;
      j += 4 / (this.farPointContianer.y / 200),
        i += (j + 10) / (this.farPointContianer.y / 200)
    ) {
      let point = GROUND_Y + i;
      this.moveTo(0, point);
      this.lineTo(800, point);
    }

    this.lineStyle({
      width: 0,
    });
  }

  @TrackTime()
  groundOffset(trackTime: number) {
    this.draw(-MEMO_SPEED * trackTime);

    // if(trackTime) {

    // }
  }

  @TrackSeq()
  start(trackSeq: number) {
    // timeStore.subscribe((store: any) => {
    // 切换模式

    if (
      trackSeq === 28.25 * BEAT_PER_METER * SEQ_PER_BEAT ||
      trackSeq === 53 * BEAT_PER_METER * SEQ_PER_BEAT
    ) {
      this.mode =
        trackSeq === 28.25 * BEAT_PER_METER * SEQ_PER_BEAT ? MODE2 : MODE1;

      gsap.to(this.roadMask, {
        y: this.mode.maskY,
        height: this.mode.maskHeight,
        duration: BPS / 16,
      });

      gsap.to(this.farPointContianer, {
        x: this.mode.farPointX,
        y: this.mode.farPointY,
        duration: BPS / 4,
      });

      gsap.to(this.bottomLineContainer, {
        y: this.mode.bottomPointY,
        duration: BPS / 4,
      });

      gsap.to(this.gridSizeContainer, {
        y: this.mode.gridSize,
        duration: 1,
      });
    }

    if (trackSeq === meterToSeq(61)) {
      (this as any).unSubgroundOffsetTime();
    }
  }
}

export default Road;
