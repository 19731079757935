import timeStore from "../store/time";

function TrackSeq() {
  return (target: any, name: string, descriptor: any) => {
    var oldFuc = descriptor.value;

    descriptor.value = function () {
      let unSub = timeStore.subscribe((store) => {
        if (this.trackSeq === undefined || this.trackSeq === null) {
          throw Error("Class need have trackSeq");
        }
        if (this.trackSeq === store.trackSeq) {
          return;
        }
        oldFuc.call(this, store.trackSeq);
        this.trackSeq = store.trackSeq;
      });

      this["unSub" + name + "Seq"] = unSub;
    };

    return descriptor;
  };
}

function TrackTime(throttleSeq = false) {
  return (target: any, name: string, descriptor: any) => {
    var oldFuc = descriptor.value;

    descriptor.value = function () {
      let unSub = timeStore.subscribe((store) => {
        if (this.trackTime === undefined || this.trackTime === null) {
          throw Error("Class need have trackSeq");
        }

        if (throttleSeq && this.trackTime === store.trackTime) {
          return;
        }

        oldFuc.call(this, store.trackTime);
        this.trackTime = store.trackTime;
      });

      this["unSub" + name + "Time"] = unSub;
    };

    return descriptor;
  };
}

export { TrackSeq, TrackTime };
