import create from "zustand/vanilla";

type IEnemyBeatInfo = {
  x: number;
  y: number;
  rank: "Just" | "Good";
};

const EnemyBeatInfoStore = create<any>((set) => ({
  enemyBeatInfo: {},
  setEnemyPos: (enemyBeatInfo: IEnemyBeatInfo) =>
    set(() => {
      return { enemyBeatInfo };
    }),
}));

// const { getState, setState, subscribe, destroy } = store
export { EnemyBeatInfoStore };

export type { IEnemyBeatInfo };
