import { Graphics } from "@pixi/graphics";
import gsap from "gsap/src/all";
import { TrackSeq } from "../decorator/trackTime";
import { meterToSeq } from "../lib/track";

class Sea extends Graphics {
  trackSeq: number = 0;
  constructor() {
    super();
    this.beginFill(0x2445ea);
    this.drawRect(0, 0, 800, 30);
    this.beginFill(0x5da2e5);
    this.drawRect(0, 30, 800, 55);
    this.y = 450;
    this.start(0);
  }
  @TrackSeq()
  start(trackSeq: number) {
    if (trackSeq === meterToSeq(28.5)) {
      gsap.to(this, {
        y: 300,
        duration: 0.2,
      });
    }
    if (trackSeq === meterToSeq(53)) {
      gsap.to(this, {
        y: 450,
        duration: 0.2,
      });
      (this as any).unSubstartSeq();
    }
  }
}

export default Sea;
