import create from "zustand/vanilla";
import { BPS, SEQ_PER_BEAT } from "../constant";
import { meterToSeq, sequenceToTime, timeToSequence } from "../lib/track";

// const START_TIME = sequenceToTime(meterToSeq(28));
const START_TIME = 0;

const timeStore = create<any>((set) => ({
  trackTime: 0,
  trackSeq: 0,

  setTimePassed: (trackTime: number) =>
    set(() => ({
      trackTime: trackTime + START_TIME,
      trackSeq: timeToSequence(trackTime + START_TIME),
    })),
}));

export default timeStore;
