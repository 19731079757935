import { Howl, Howler } from "howler";

// Setup the new Howl.
const sound = new Howl({
  src: ["/assets/midnight-lady.mp3"],
  onload: () => {},
});

// Play the sound.
// sound.play();

export default sound;
