const Tik: any = {
  subs: [],
};

Tik.subscribe = (cb: any) => {
  Tik.subs.push(cb);
};

Tik.run = (timer: number, dt: number, ms: number) => {
  for (const iterator of Tik.subs) {
    iterator(timer, dt, ms);
  }
};

export default Tik;
