import { useCallback, useEffect, useRef, useState } from "react";
import PlayerX from "./PlayerX";
import { Container, Sprite } from "pixi.js";
import dayjs from "dayjs";
import Tik from "../lib/Tik";
import keyStore from "../store/key";
import timeStore from "../store/time";
// import {
//   FloatShapes,
//   FloatShapesContainer,
//   FloatShapesMasks,
// } from "./RoadGround/FloatShapes";

import { Camera3d, Container3d, Sprite3d } from "pixi-projection";
import { STAGE_WIDTH } from "../constant";
import RoadGround from "./RoadGround";
import RoadBlock from "./RoadGround/index";
import { getImageTexture } from "../lib/getImageTexture";
import Sky from "../BackGround/day";
import Enemys from "./Enemys";
import David from "./David";
import Fujin from "./Fujin";

class RoadUI extends Container {
  // floatShapesMask: FloatShapesMasks;
  player: PlayerX;
  roadGround: RoadGround;
  enemysContainer: Enemys;
  david: Sprite;
  fujin: Sprite;

  constructor() {
    super();

    this.width = STAGE_WIDTH;
    this.height = 300;
    this.x = 0;
    this.y = 0;

    // 天空
    let sky = new Sky();
    this.addChild(sky);

    // cloud
    // let sky = ImageResources.road;
    // sky.width = 800;
    // sky.height = 400;
    // this.addChild(sky);

    // this.floatShapesMask = new FloatShapesMasks();
    // this.addChild(this.floatShapesMask);

    // // 道路
    // this.addChild(this.roadMap);
    // this.roadMap.zIndex = 100;

    // 3d地面
    this.roadGround = new RoadGround();
    this.addChild(this.roadGround);

    // 角色
    this.player = new PlayerX();
    this.addChild(this.player);

    // 大卫：

    this.david = new David();
    this.addChild(this.david);

    // 雕像

    this.fujin = new Fujin();
    this.addChild(this.fujin);

    // 敌人

    this.enemysContainer = new Enemys();
    this.addChild(this.enemysContainer);

    // this.fujin.position.copyFrom(this.david);

    keyStore.subscribe(
      ({ keyName }: any) => {
        if (keyName === "Space" || keyName === "Spac") {
          this.player.jump();
        }
      },
      (state: any) => state.key
    );
  }

  start(): void {
    this.roadGround.start();
    this.enemysContainer.start();
  }
}

export default RoadUI;
