import { Container, Graphics, Loader, LoaderResource, Sprite } from "pixi.js";
import { BEAT_PER_METER, BPS, SEQ_PER_BEAT } from "../constant";
import timeStore from "../store/time";
import Buildings from "./Buildings";
import gsap from "gsap";
import { gsapToPromise, meterToSeq } from "../lib/track";
import Sun from "./Sun";
import { ImageResources } from "../lib/imgLoader";
import BridgeWall from "./BridgeWall";
import { FloatShapes, FloatShapesContainer } from "./FloatShapes";
import Sea from "./Sea";

const SUN_INITIAL_SCALE = 1.5;
class Sky extends Container {
  sun: Sun;
  sunNight: Sun;
  // cloud: Sprite;
  buildings: Buildings;
  trackSeq: number = 0;
  sky: Sprite;
  bridgeWall: BridgeWall;
  floatShapes: FloatShapesContainer;
  sea: Sea;

  constructor() {
    super();

    this.sky = new Sprite(ImageResources.sky.texture);
    // this.sky = Loader.this.sky.width = 800;
    this.sky.height = 900;
    this.sky.y = -500;

    this.addChild(this.sky);

    // 太阳
    this.sun = new Sun();
    this.addChild(this.sun);

    // 太阳
    this.sunNight = new Sun(true);
    this.addChild(this.sunNight);

    this.floatShapes = new FloatShapesContainer();
    this.addChild(this.floatShapes);

    // 楼房啊
    this.buildings = new Buildings();
    this.addChild(this.buildings);

    this.sea = new Sea();
    this.addChild(this.sea);

    // // 云
    // this.cloud = new Sprite(ImageResources.cloud.texture);
    // this.addChild(this.cloud);

    // 墙
    this.bridgeWall = new BridgeWall();
    this.addChild(this.bridgeWall);

    this.start();
  }
  start() {
    this.buildings.start();
    this.sun.start();
    this.sunNight.start();

    timeStore.subscribe((store) => {
      if (this.trackSeq === store.trackSeq) {
        return;
      }

      if (store.trackSeq === meterToSeq(28.75)) {
        gsap.to(this.sky, {
          y: 0,
          height: 700,
        });
      }

      if (store.trackSeq === meterToSeq(53)) {
        gsap.to(this.sky, {
          y: 600,
        });
      }

      this.trackSeq = store.trackSeq;
    });
  }
}

export default Sky;
