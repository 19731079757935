// import { useCallback, useEffect, useRef, useState } from "react";
// import useKeyStore from "../../store/keyStore";

import dayjs from "dayjs";
import { AnimatedSprite, Container, Graphics } from "pixi.js";
import { Component } from "react";
import Tik from "../lib/Tik";

import { physics } from "popmotion";
import { TrackSeq, TrackTime } from "../decorator/trackTime";
import gsap from "gsap/src/all";
import { meterToSeq, timeToSequence } from "../lib/track";
import { ImageResources } from "../lib/imgLoader";
import { BLOCK_JSON } from "../constant/RoadBlockTrack";
import keyStore from "../store/key";
import scoreStore from "../store/score";
import timeStore from "../store/time";

const GRAVITY = 3;
const INITIAL_SPEED = -30;
const HIGH_SPEED = -40;

const INIT_Y = 370;

// gsap.to(element, {duration: 2, physics2D: {velocity: 300, angle: -60, gravity: 400}});

//this.enemyMap.get(hitedSeq).x

class PlayerX extends Container {
  private speed: number = 0;
  private wq: AnimatedSprite;
  private isJumping: boolean = false;
  private board: AnimatedSprite;
  private arm: AnimatedSprite;
  private jumpingTime: number = 0;
  private trackSeq: number = 0;
  private trackTime: number = 0;
  slopeStartTime: number = 0;
  constructor() {
    super();

    this.y = INIT_Y;
    this.x = -100;

    this.board = new AnimatedSprite(
      new Array(3).fill(0).map((item, index) => {
        return ImageResources[`board${index + 1}`].texture!;
      })
    );

    this.board.play();
    this.board.y = 95;
    this.board.x = 8;
    this.board.animationSpeed = 0.3;
    this.addChild(this.board);
    this.slopeStartTime = 0;

    this.wq = new AnimatedSprite(
      new Array(10).fill(0).map((item, index) => {
        return ImageResources[`wq${index + 1}`].texture!;
      })
    );

    this.wq.play();
    this.wq.animationSpeed = 0.3;
    this.addChild(this.wq);

    this.arm = new AnimatedSprite(
      new Array(4).fill(0).map((item, index) => {
        return ImageResources[`arm${index + 1}`].texture!;
      })
    );
    this.addChild(this.arm);
    this.arm.animationSpeed = 0.7;
    this.arm.x = 50;
    this.arm.y = -20;
    this.arm.loop = false;

    keyStore.subscribe((store: any) => {
      if (store.key.keyName !== "KeyA") {
        return;
      }

      this.arm.play();
      // this.arm.onComplete!();
    });
    this.arm.onFrameChange = (curr) => {
      if (curr === 3) {
        this.arm.gotoAndStop(0);
      }
    };

    this.goAway(0);

    Tik.subscribe((dt: number) => {
      if (!this.isJumping) {
        return;
      }
      this.speed += GRAVITY * dt;
      this.y += this.speed * dt;

      if (this.y > INIT_Y) {
        this.isJumping = false;
        this.y = INIT_Y;
      }
    });
  }

  jump() {
    if (this.isJumping) {
      return;
    }

    console.log(
      BLOCK_JSON[timeStore.getState().trackSeq] &&
        !BLOCK_JSON[timeStore.getState().trackSeq].jumped &&
        BLOCK_JSON[timeStore.getState().trackSeq].type === 2
    );

    this.speed =
      BLOCK_JSON[timeStore.getState().trackSeq] &&
      !BLOCK_JSON[timeStore.getState().trackSeq].jumped &&
      BLOCK_JSON[timeStore.getState().trackSeq].type === 2
        ? HIGH_SPEED
        : INITIAL_SPEED;

    this.isJumping = true;
    this.jumpingTime = dayjs().valueOf();
  }

  @TrackTime()
  up(trackTime: number) {
    if (BLOCK_JSON[timeToSequence(trackTime)]) {
      this.y += this.slopeStartTime / 10;
    }
  }

  @TrackSeq()
  goAway(trackSeq: number) {
    // this.x = trackSeq

    if (trackSeq === meterToSeq(0.5)) {
      gsap.to(this, {
        x: 120,
        duration: 1,
      });
    }

    if (trackSeq === meterToSeq(61)) {
      gsap.to(this, {
        x: 800,
        ease: "linear",
        duration: 1,
      });
    }

    if (trackSeq === meterToSeq(62)) {
      scoreStore.getState().setDone();
      // (this as any).unSubgoAwaySeq();
    }
  }
}

export default PlayerX;
