import { Graphics, Sprite } from "pixi.js";
import { BEAT_PER_METER, SEQ_PER_BEAT } from "../constant";
import { gsapToPromise, meterToSeq } from "../lib/track";
import timeStore from "../store/time";
import { gsap } from "gsap";
import { ImageResources } from "../lib/imgLoader";
import { ENEMY_ARRAY } from "../constant/EnemyTrack";

const ROTATE_ANGLE = Math.PI / 12;
const TIME = 0.5;

console.log(ENEMY_ARRAY);

let saxArray = ENEMY_ARRAY.filter((item) => {
  return item >= meterToSeq(21) && item <= meterToSeq(28);
});

console.log(saxArray);

class Fujin extends Sprite {
  trackSeq: number = 0;
  constructor() {
    super(ImageResources.fujin.texture);
    this.anchor.set(0.5, 0.5);
    this.x = 900;
    this.y = 150;

    // console.log(this.parent, "???");
    this.roll();
    this.start();
  }

  async roll() {
    await gsapToPromise(this, {
      rotation: ROTATE_ANGLE,
      // ease: "ease-out",
      duration: 0.1,
    });
    await gsapToPromise(this, {
      rotation: 0,
      ease: "linear",
      duration: 0.2,
    });
  }

  start() {
    timeStore.subscribe(async (store) => {
      if (store.trackSeq === this.trackSeq) {
        return;
      }

      if (saxArray.includes(store.trackSeq + 16)) {
        this.roll();
      }

      if (store.trackSeq === 18 * BEAT_PER_METER * SEQ_PER_BEAT) {
        gsap.to(this, {
          x: 700,
          y: 350,
          duration: 1,
        });
      }
      if (store.trackSeq === 28 * BEAT_PER_METER * SEQ_PER_BEAT) {
        await gsapToPromise(this, {
          x: 900,
          y: 350,
          duration: 1,
        });
      }

      this.trackSeq = store.trackSeq;
    });
  }
}

export default Fujin;
