import create from "zustand";
import { isNil } from "ramda";
import { SetState } from "zustand";

type Zustand<T extends object> = ReturnType<SetState<T>>;

type hitType = "Miss" | "Just" | "Good";

type scoreState = {
  score: number;
  hit: {
    type: string | null;
    combo: number;
  };
  done: boolean;
  maxCombo: number;
  setScore: (score: number) => Zustand<scoreState>;
  setDone: () => Zustand<scoreState>;
  setHit: (hitType: hitType) => Zustand<scoreState>;
};

const scoreStore = create<scoreState>((set) => ({
  score: 0,
  hit: {
    type: null,
    combo: 0,
  },
  maxCombo: 0,
  done: false,
  setDone: () =>
    set(() => ({
      done: true,
    })),
  setScore: (score: number) =>
    set(() => ({
      score,
    })),
  setHit: (hitType: hitType) =>
    set((state) => {
      //命中
      if (hitType === "Good" || hitType === "Just") {
        return {
          maxCombo:
            state.hit.combo + 1 > state.maxCombo
              ? state.hit.combo + 1
              : state.maxCombo,
          hit: {
            type: hitType,
            combo: state.hit.combo + 1,
          },
        };
        //不命中
      } else {
        return {
          maxCombo: state.maxCombo,
          hit: {
            type: hitType,
            combo: 0,
          },
        };
      }
    }),
}));

export default scoreStore;
