import dayjs from "dayjs";
import * as PIXI from "pixi.js";
import TrackUI from "./track/TrackUI";
import UI from "./UI/UI";
import Tik from "./lib/Tik";
import RoadUI from "./Road/RoadUI";

import keyStore from "./store/key";

import store from "./store/time";
import { keys } from "ramda";
import timeStore from "./store/time";

import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";

import musicTrack from "./music/index";

const StartPIXIGame = (canvas: HTMLCanvasElement) => {
  gsap.registerPlugin(PixiPlugin);
  // gsap.registerPlugin(Physics2DPlugin);

  PixiPlugin.registerPIXI(PIXI);
  setup(canvas);
};

const setup = (canvas: HTMLCanvasElement) => {
  console.log(canvas);
  const app = new PIXI.Application({
    width: 800,
    height: 600,
    backgroundColor: 0x000000,
    antialias: false,
    view: canvas!,
  });

  app.view.style.imageRendering = "crisp-edges";

  let firedSpace = false;
  let firedA = false;

  document.onkeydown = function (e) {
    if (firedA && e.code === "KeyA") {
      return;
    }
    if (firedSpace && (e.code === "Space" || e.code === "Spac")) {
      return;
    }

    if (e.code === "Space" || e.code === "Spac") {
      firedSpace = true;
    } else if (e.code === "KeyA") {
      firedA = true;
    }
    // if ()

    if (["KeyA", "KeyS", "KeyD", "KeyF", "Space", "Spac"].includes(e.code)) {
      keyStore.getState().setKeyPress(e.code);
    }
  };

  document.onkeyup = function (e) {
    if (e.code === "Space" || e.code === "Spac") {
      firedSpace = false;
    } else if (e.code === "KeyA") {
      firedA = false;
    }
  };

  let roadUI = new RoadUI();
  let ui = new UI();

  app.stage.addChild(roadUI);
  app.stage.addChild(ui);

  window.setTimeout(() => {
    let startTime = dayjs().valueOf();
    roadUI.start();
    ui.start();
    musicTrack.play();
    app.ticker.add((dt) => {
      timeStore.getState().setTimePassed(dayjs().valueOf() - startTime);
      Tik.run(dt, app.ticker.deltaMS);
    });
  }, 0);
};

export default StartPIXIGame;
