import { Graphics, Matrix } from "pixi.js";
import { SEQ_PER_BEAT, MEMO_SPEED, BEAT_PER_METER } from "../../constant";
import { seqToPixel } from "../../lib/track";
import timeStore from "../../store/time";
import { ImageResources } from "../../lib/imgLoader";

const START_TREE = 5 * BEAT_PER_METER;
const TREE_COUNT = 16 * BEAT_PER_METER;

const START_SECOND_TREE = 55 * BEAT_PER_METER;
const SECOND_TREE_COUNT = 6 * BEAT_PER_METER;

const TREE_WIDTH = 161;
const TREE_HEIGHT = 165;

class Tree extends Graphics {
  constructor() {
    super();
    this.draw(0);
  }
  draw(offset: number) {
    this.clear();
    for (let i = START_TREE; i < START_TREE + TREE_COUNT; i += 2) {
      this.beginTextureFill({
        alpha: 1,
        color: 0xffffff,
        texture: ImageResources.tree.texture,

        matrix: new Matrix(
          1,
          0,
          0,
          1,
          seqToPixel(i * SEQ_PER_BEAT) * 0.75,
          600 - TREE_HEIGHT - 180
        ),
      });

      this.drawRect(
        seqToPixel(Number(i * SEQ_PER_BEAT)) * 0.75,
        600 - TREE_HEIGHT - 180,
        TREE_WIDTH,
        TREE_HEIGHT
      );
      this.endFill();
    }

    for (
      let i = START_SECOND_TREE;
      i < START_SECOND_TREE + SECOND_TREE_COUNT;
      i += 2
    ) {
      this.beginTextureFill({
        alpha: 1,
        color: 0xffffff,
        texture: ImageResources.tree.texture,
        matrix: new Matrix(
          1,
          0,
          0,
          1,
          seqToPixel(i * SEQ_PER_BEAT) * 0.75,
          600 - TREE_HEIGHT - 180
        ),
      });

      this.drawRect(
        seqToPixel(Number(i * SEQ_PER_BEAT)) * 0.75,
        600 - TREE_HEIGHT - 180,
        TREE_WIDTH,
        TREE_HEIGHT
      );
      this.endFill();
    }
  }
  start() {
    timeStore.subscribe((store: any) => {
      this.x = -MEMO_SPEED * store.trackTime * 0.75;
    });
  }
}

export default Tree;
