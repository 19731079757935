import { Graphics, Sprite } from "pixi.js";
import { BEAT_PER_METER, SEQ_PER_BEAT, STAGE_WIDTH } from "../constant";
import { gsapToPromise } from "../lib/track";
import gsap from "gsap";
import timeStore from "../store/time";
import { ImageResources } from "../lib/imgLoader";

const ROTATE_ANGLE = Math.PI / 12;

const SUN_INITIAL_SCALE = 1.5;

const SUN_START = 4 * BEAT_PER_METER * SEQ_PER_BEAT;
const SUN_LOST = 28 * BEAT_PER_METER * SEQ_PER_BEAT;

const SUN_NIGHT_START = 53 * BEAT_PER_METER * SEQ_PER_BEAT;
const SUN_NIGHT_LOST = 61 * BEAT_PER_METER * SEQ_PER_BEAT;

class Sun extends Sprite {
  trackSeq: number = 0;
  startTime: number;
  lostTime: number;
  constructor(isNight = false) {
    super(
      isNight ? ImageResources.sunNight.texture : ImageResources.sun.texture
    );
    this.anchor.set(0.5, 0.5);
    this.x = STAGE_WIDTH / 2;
    this.y = 360;
    this.width = 200;
    this.height = 210;
    this.scale.set(1);
    this.startTime = isNight ? SUN_NIGHT_START : SUN_START;
    this.lostTime = isNight ? SUN_NIGHT_LOST : SUN_LOST;

    this.alpha = 0;
  }

  start() {
    let unsubscribe = timeStore.subscribe(async (store: any) => {
      if (this.trackSeq === store.trackSeq) {
        return;
      }

      if (store.trackSeq === this.startTime) {
        gsap.to(this.scale, {
          x: SUN_INITIAL_SCALE,
          y: SUN_INITIAL_SCALE,
          duration: 0.2,
        });
        gsap.to(this, {
          alpha: 1,
          duration: 0.2,
        });
      }

      if (store.trackSeq === this.lostTime) {
        gsap.to(this, {
          alpha: 0,
          duration: 0.5,
        });
        unsubscribe();
      }

      //
      if (
        store.trackSeq > this.startTime &&
        store.trackSeq <= this.lostTime &&
        store.trackSeq % SEQ_PER_BEAT === 0
      ) {
        await gsapToPromise(this.scale, {
          x: 1.6,
          y: 1.6,
          duration: 0.05,
        });
        await gsapToPromise(this.scale, {
          x: 1.5,
          y: 1.5,
          duration: 0.1,
        });
      }

      this.trackSeq = store.trackSeq;
    });
  }
}

export default Sun;
