import { Graphics, Matrix } from "pixi.js";
import {
  BPS,
  MEMO_SPEED,
  PLAYER_X,
  ROAD_PIXEL_PER_BEAT,
  SEQ_PER_BEAT,
} from "../../constant";
import { seqToPixel, timeToSequence } from "../../lib/track";
import scoreStore from "../../store/score";
import timeStore from "../../store/time";
import keyStore from "../../store/key";
import { ImageResources } from "../../lib/imgLoader";
import { BLOCK_JSON, IBlockJson } from "../../constant/RoadBlockTrack";

const SLOPE_SIZE = [97, 57];
const ROAD_BLOCK_SIZE = [46, 66];

class RoadBlock extends Graphics {
  blockJson: IBlockJson = BLOCK_JSON;
  trackTime: number = 0;
  trackSeq: number = 0;

  draw(blockLayoutOffset: number) {
    this.clear();
    // 画浮标

    for (const key in this.blockJson) {
      // 只需渲染屏幕以内的画面
      if (parseInt(key) < this.trackSeq - 16) {
        continue;
      }

      if (parseInt(key) > this.trackSeq + 128) {
        break;
      }

      let isBlock = this.blockJson[key].type === 1;

      let SIZE = isBlock ? ROAD_BLOCK_SIZE : SLOPE_SIZE;
      let preJudge = isBlock ? 80 : 0;
      // 位置 + 时间偏移量 + 玩家位置 + 预判
      let x = seqToPixel(Number(key)) + blockLayoutOffset + PLAYER_X + preJudge;

      this.beginTextureFill({
        alpha: 1,
        color: 0xffffff,
        texture: ImageResources[isBlock ? "roadBlock" : "slope"].texture,
        matrix: new Matrix(1, 0, 0, 1, x, 600 - SIZE[1] - 110),
      });

      this.drawRect(x, 600 - SIZE[1] - 110, SIZE[0], SIZE[1]);
      this.endFill();
    }
  }
  checkMiss() {
    let notes = this.blockJson[this.trackSeq - 3];
    if (notes && !notes.jumped && !notes.checked) {
      notes.checked = true;
      scoreStore.getState().setHit("Miss");
    }
  }
  start() {
    timeStore.subscribe((store: any) => {
      if (this.trackSeq !== store.trackSeq) {
        this.checkMiss();
      }
      this.draw(-MEMO_SPEED * store.trackTime);
      this.trackTime = store.trackTime;
      this.trackSeq = store.trackSeq;
    });

    keyStore.subscribe(
      ({ keyName }: any) => {
        if (keyName !== "Spac" && keyName !== "Space") {
          return;
        }

        let hitedSeq = timeToSequence(this.trackTime);

        if (!hitedSeq) {
          return;
        }

        if (this.blockJson[hitedSeq] && !this.blockJson[hitedSeq].jumped) {
          this.blockJson[hitedSeq].jumped = true;
          scoreStore.getState().setHit("Just");
        }

        if (
          this.blockJson[hitedSeq + 1] &&
          !this.blockJson[hitedSeq + 1].jumped
        ) {
          this.blockJson[hitedSeq + 1].jumped = true;
          scoreStore.getState().setHit("Good");
        } else if (
          this.blockJson[hitedSeq - 1] &&
          !this.blockJson[hitedSeq - 1].jumped
        ) {
          this.blockJson[hitedSeq - 1].jumped = true;
          scoreStore.getState().setHit("Good");
        }
      },
      (state: any) => state.key
    );
  }
}

export default RoadBlock;
