import { Container, Graphics } from "pixi.js";
import { SEQ_PER_BEAT } from "../constant";
import { TrackSeq, TrackTime } from "../decorator/trackTime";
import { gsapToPromise, meterToSeq, timeToSequence } from "../lib/track";
import timeStore from "../store/time";

enum shapeType {
  "triangle",
  "rect",
  "buzz",
}

type ShapeState = {
  x: number;
  y: number;
  size: number;
  shapeType: string;
  rotation: number;
};

const SCENE_WIDTH = 800;
const SCENE_HEIGHT = 300;
const SIZE_RANGE = [8, 15];
const PARTICLE_NUM = 30;

class FloatShapesMasks extends Graphics {
  constructor() {
    super();
    this.width = SCENE_WIDTH;
    this.height = SCENE_HEIGHT;
    this.lineStyle(0);
    this.drawRect(0, 0, SCENE_WIDTH, SCENE_HEIGHT);
  }
}

class FloatShapesContainer extends Container {
  floatShapes: FloatShapes;
  trackSeq: number = 0;
  trackTime: number = 0;
  gsapScaleContainer: Graphics;

  constructor() {
    super();
    this.floatShapes = new FloatShapes();
    this.addChild(this.floatShapes);
    this.gsapScaleContainer = new Graphics();
    this.gsapScaleContainer.width = 4;
    this.gsapScaleContainer.height = 4;
    this.gsapScaleContainer.drawRect(0, 0, 4, 4);
    this.addChild(this.gsapScaleContainer);
    this.showAndHide(0);
  }

  @TrackSeq()
  showAndHide(trackSeq: number) {
    if (trackSeq === meterToSeq(30)) {
      this.floatShapes.start(this.gsapScaleContainer);
      this.start(0);
    }

    if (trackSeq === meterToSeq(53)) {
      (this as any).unSubstartTime();
      this.removeChild(this.floatShapes);
    }

    // if (trackSeq === meterToSeq(28)) {
    //   this.floatShapes.start(this.gsapScaleContainer);
    //   this.start(0);
    // }
  }
  @TrackTime()
  start(trackTime: number) {
    if (timeToSequence(trackTime) % SEQ_PER_BEAT === 0) {
      (async () => {
        await gsapToPromise(this.gsapScaleContainer, {
          width: 8,
          height: 8,
          duration: 0.1,
        });
        await gsapToPromise(this.gsapScaleContainer, {
          width: 0,
          height: 0,
          duration: 0.1,
        });
      })();
    }
  }
}

class FloatShapes extends Graphics {
  private shapes: Array<ShapeState>;
  constructor() {
    super();
    this.shapes = [];
    this.x = 0;
    this.y = 0;
    this.width = SCENE_WIDTH;
    this.height = SCENE_HEIGHT;

    for (let i = 0; i < PARTICLE_NUM; i++) {
      let shape: ShapeState = {
        x: Math.random() * SCENE_WIDTH,
        y: Math.random() * SCENE_HEIGHT + SCENE_HEIGHT,
        size: Math.random() * SIZE_RANGE[0] + SIZE_RANGE[1] - SIZE_RANGE[0],
        shapeType: shapeType[~~(Math.random() * 3)],
        rotation: 0,
      };

      this.shapes.push(shape);
    }
  }

  start(gsapScaleContainer: Container) {
    timeStore.subscribe(({ trackTime, trackSeq }, oldTrack: any) => {
      this.clear();
      // average: 0.03ms

      // console.time("ds");
      for (let i = 0; i < this.shapes.length; i++) {
        this.lineStyle(3, 0xdcc6ff, 1);
        let angle0 = (Math.PI / 180) * (45 + this.shapes[i].rotation);
        let angle1 = (Math.PI / 180) * (135 + this.shapes[i].rotation);
        let angle2 = (Math.PI / 180) * (225 + this.shapes[i].rotation);
        let angle3 = (Math.PI / 180) * (315 + this.shapes[i].rotation);

        let triangleAngle0 = (Math.PI / 180) * (90 + this.shapes[i].rotation);
        let triangleAngle1 = (Math.PI / 180) * (210 + this.shapes[i].rotation);
        let triangleAngle2 = (Math.PI / 180) * (330 + this.shapes[i].rotation);

        let shapeSize = this.shapes[i].size + gsapScaleContainer.width;

        switch (this.shapes[i].shapeType) {
          case shapeType[1]:
            this.moveTo(
              Math.cos(angle0) * shapeSize + this.shapes[i].x,
              Math.sin(angle0) * shapeSize + this.shapes[i].y
            );

            this.lineTo(
              Math.cos(angle1) * shapeSize + this.shapes[i].x,
              Math.sin(angle1) * shapeSize + this.shapes[i].y
            );

            this.lineTo(
              Math.cos(angle2) * shapeSize + this.shapes[i].x,
              Math.sin(angle2) * shapeSize + this.shapes[i].y
            );

            this.lineTo(
              Math.cos(angle3) * shapeSize + this.shapes[i].x,
              Math.sin(angle3) * shapeSize + this.shapes[i].y
            );

            this.lineTo(
              Math.cos(angle0) * shapeSize + this.shapes[i].x,
              Math.sin(angle0) * shapeSize + this.shapes[i].y
            );

            break;
          case shapeType[2]:
            this.moveTo(
              Math.cos(triangleAngle0) * shapeSize + this.shapes[i].x,
              Math.sin(triangleAngle0) * shapeSize + this.shapes[i].y
            );

            this.lineTo(
              Math.cos(triangleAngle1) * shapeSize + this.shapes[i].x,
              Math.sin(triangleAngle1) * shapeSize + this.shapes[i].y
            );

            this.lineTo(
              Math.cos(triangleAngle2) * shapeSize + this.shapes[i].x,
              Math.sin(triangleAngle2) * shapeSize + this.shapes[i].y
            );

            this.lineTo(
              Math.cos(triangleAngle0) * shapeSize + this.shapes[i].x,
              Math.sin(triangleAngle0) * shapeSize + this.shapes[i].y
            );
            break;
        }

        this.shapes[i].rotation = trackTime / 2;

        // respawn
        if (this.shapes[i].y < 0) {
          this.shapes[i].y = Math.random() * SCENE_HEIGHT + SCENE_HEIGHT;
          this.shapes[i].x = Math.random() * SCENE_WIDTH;
        }
        // FLOAT
        this.shapes[i].y -= this.shapes[i].size / 8;
        // this.shapes[i].y = (2000 - (trackTime % 2000)) / 4;
        // console.log(this.shapes[i].y);
      }
      // console.timeEnd("ds");
    });

    // this.drawRect(t)
  }
}

export { FloatShapes, FloatShapesMasks, FloatShapesContainer };
